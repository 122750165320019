import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import classes from './UNCar.module.css';

const API_URL = `${window.location.protocol}//db.kenjap.co.ke`;

function NewCar() {
	const [carData, setCarData] = useState([]);
	const [makes, setMakes] = useState([]);
	const [models, setModels] = useState([]);
	const [selectedMake, setSelectedMake] = useState('');
	const [selectedModel, setSelectedModel] = useState('');
	const [selectedYear, setSelectedYear] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const [featuredOnly, setFeaturedOnly] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const carResponse = await axios.get(`${API_URL}/api/new-cars`);
				const initialCars = carResponse.data;
				setCarData(initialCars);
				setMakes([...new Set(initialCars.map((car) => car.make))].sort());
				setSearchResults(initialCars);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		if (selectedMake) {
			const fetchModels = async () => {
				try {
					const response = await axios.get(
						`${API_URL}/api/vehicles/models/${selectedMake}`
					);
					setModels(response.data.sort());
				} catch (error) {
					console.error('Error fetching models:', error);
				}
			};
			fetchModels();
		} else {
			setModels([]);
		}
	}, [selectedMake]);

	const handleMakeChange = (event) => {
		const make = event.target.value;
		setSelectedMake(make);
		setSelectedModel('');
		updateSearchResults(make, '', selectedYear);
	};

	const handleModelChange = (event) => {
		const model = event.target.value;
		setSelectedModel(model);
		updateSearchResults(selectedMake, model, selectedYear);
	};

	const handleYearChange = (event) => {
		const year = event.target.value;
		setSelectedYear(year);
		updateSearchResults(selectedMake, selectedModel, year);
	};

	const handleSortFeatured = (event) => {
		const value = event.target.value;
		const featured = value === 'featured';
		setFeaturedOnly(featured);
		updateSearchResults(selectedMake, selectedModel, selectedYear, featured);
	};

	const updateSearchResults = (make, model, year) => {
		const filteredCars = carData.filter((car) => {
			const isMakeMatch = make ? car.make === make : true;
			const isModelMatch = model ? car.model === model : true;
			const isYearMatch = year
				? year === 'Below 2015'
					? parseInt(car.year) <= 2015
					: year === 'Current year'
					? parseInt(car.year) === new Date().getFullYear()
					: parseInt(car.year) === parseInt(year)
				: true;
			return isMakeMatch && isModelMatch && isYearMatch;
		});
		setSearchResults(filteredCars);
	};

	const formatPrice = (price) => {
		return Number(price).toLocaleString('en-US', { maximumFractionDigits: 0 });
	};

	return (
		<div className={classes.newCars}>
			<div className={classes.wrapper}>
				<div className={classes.filters}>
					<select
						className={classes.dropdown}
						onChange={handleMakeChange}
						value={selectedMake}>
						<option value=''>Select Make</option>
						{makes.map((make) => (
							<option
								key={make}
								value={make}>
								{make}
							</option>
						))}
					</select>
					<select
						className={classes.dropdown}
						onChange={handleModelChange}
						value={selectedModel}
						disabled={!selectedMake}>
						<option value=''>Select Model</option>
						{models.map((model) => (
							<option
								key={model}
								value={model}>
								{model}
							</option>
						))}
					</select>
					<select
						className={classes.dropdown}
						onChange={handleYearChange}
						value={selectedYear}>
						<option value=''>Select Year</option>
						{[
							'Below 2015',
							...Array.from(
								{ length: new Date().getFullYear() - 2015 + 1 },
								(_, index) => new Date().getFullYear() - index
							).reverse(),
							'Current year',
						].map((year, index) => (
							<option
								key={index}
								value={year}>
								{year}
							</option>
						))}
					</select>
					<select
						className={classes.dropdown}
						onChange={handleSortFeatured}
						value={featuredOnly ? 'featured' : 'all'}>
						<option value='all'>Show All</option>
						<option value='featured'>On Offer</option>
					</select>
				</div>

				<div className={classes.results}>
					{searchResults.length > 0 ? (
						searchResults.map((car, index) => (
							<div
								className={classes.vehicle}
								key={index}
								onClick={() => {
									navigate(`/car/${car.id}`);
								}}>
								<div className={classes.image}>
									{car.images && car.images.length > 0 && (
										<img
											src={`${API_URL}/images/cars/${car.images[0].imageName}`}
											alt={`${car.make} ${car.model}`}
										/>
									)}
									<p className={car.featured ? classes.offer : classes.hidden}>
										On Offer!!
									</p>
								</div>
								<div className={classes.desc}>
									<h3>{`${car.make} ${car.model}`}</h3>
									<div className={classes.descFlex}>
										<h4>
											<span>ksh. </span>
											{formatPrice(car.price)}
										</h4>
										<p
											onClick={() => {
												navigate(`/car/${car.id}`);
											}}>
											Shop now
										</p>
									</div>
								</div>
							</div>
						))
					) : (
						<p>No results found.</p>
					)}
				</div>
			</div>
		</div>
	);
}

export default NewCar;
